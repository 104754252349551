.ant-layout-content {
  top: 160px;
  left: 20px;
  right: 20px;
  z-index: 999;
  font-size: 16px;
  padding: 20px;
}

.scroll-container {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
  padding-right: 2rem;
}

.block100w100hAbsolute {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.block100w100hRelative {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.templateThumbnail {
  height: 100%;
  width: 100%;
  border: 1px solid silver;
  position: absolute;
  overflow: clip;
  cursor: pointer;
}
