@media print {
    body {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
    }
    .print-section {
      width: 100%;
      max-width: 900px;
    }
    .print-title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 16px;
    }
  }
  